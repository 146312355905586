<script setup lang="ts">
import Navigation from '~/components/layout/Navigation.vue';
import EventBoxList from '~/components/events/EventBoxList.vue';
import Button from 'goout-ui-kit/button/Button.vue';
import { computed, watch, onUnmounted } from 'vue';
import { useJsonApiClient } from 'goout-api';
import { useDataStore } from '~/store/data.store';
import { TabEnum } from '~/store/tabs.store';
import { useSessionStore } from '~/store/session.store';
import TopbarOverview from '../layout/TopbarOverview.vue';
import { useRouter } from 'vue-router';
import { useEventBus } from '@vueuse/core';
import { useTracking } from '~/composables/useTracking';

const router = useRouter();
const dataStore = useDataStore();
const sessionStore = useSessionStore();
const bus = useEventBus('refetch-schedules');
const { trackPageView } = useTracking();
const pageSize = 30;
const include = ['events', 'venues', 'images'];
const contactIds = computed(() =>
  sessionStore.selectedContact.id ? [sessionStore.selectedContact.id] : []
);

const now = new Date().toISOString();

// Published events - current events that are already published
const {
  data: publishedSchedulesData,
  isFetching: isPublishedFetching,
  execute: fetchPublishedSchedules,
  abort: abortPublishedSchedules,
} = useJsonApiClient({
  entity: 'schedules',
  baseUrl: 'entitystore/v2',
  include,
  contactIds,
  pageSize,
  after: now,
  filterPlanned: true,
  immediate: false,
});

// Scheduled events - future events that are not yet published
const {
  data: scheduledSchedulesData,
  isFetching: isScheduledFetching,
  execute: fetchScheduledSchedules,
  abort: abortScheduledSchedules,
} = useJsonApiClient({
  entity: 'schedules',
  baseUrl: 'entitystore/v2',
  include,
  contactIds,
  pageSize,
  after: now,
  filterPlanned: false,
  immediate: false,
});

// Past events
const {
  data: pastSchedulesData,
  isFetching: isPastFetching,
  execute: fetchPastSchedules,
  abort: abortPastSchedules,
} = useJsonApiClient({
  entity: 'schedules',
  baseUrl: 'entitystore/v2',
  include,
  contactIds,
  pageSize,
  before: now,
  immediate: false,
});

const isLoading = computed(
  () =>
    isPublishedFetching.value ||
    isScheduledFetching.value ||
    isPastFetching.value
);

const isAborted = computed(
  () =>
    !publishedSchedulesData.value &&
    !scheduledSchedulesData.value &&
    !pastSchedulesData.value
);

// Direct mapping from API responses to schedule lists
const publishedSchedules = computed(() => publishedSchedulesData.value || []);
const scheduledSchedules = computed(() => scheduledSchedulesData.value || []);
const pastSchedules = computed(() => pastSchedulesData.value || []);

const targetRoute = computed(() => {
  const totalSchedules =
    (publishedSchedulesData.value?.length || 0) +
    (scheduledSchedulesData.value?.length || 0) +
    (pastSchedulesData.value?.length || 0);

  return totalSchedules > 3 || !isAborted.value
    ? {
        name: 'Wizard',
        params: { step: TabEnum.General },
      }
    : { name: 'Intro' };
});

// Update store when any data changes
watch(
  [publishedSchedulesData, scheduledSchedulesData, pastSchedulesData],
  () => {
    if (!contactIds.value) return;

    const allSchedules = [
      ...(publishedSchedulesData.value || []),
      ...(scheduledSchedulesData.value || []),
      ...(pastSchedulesData.value || []),
    ];

    dataStore.setSchedules(allSchedules);

    if (allSchedules.length === 0 && contactIds.value?.length > 0) {
      router.push({ name: 'Intro' });
    }
  }
);

// Fetch all data when contact changes
watch(
  contactIds,
  async () => {
    if (!contactIds.value.length) return;
    await Promise.all([
      fetchPublishedSchedules(),
      fetchScheduledSchedules(),
      fetchPastSchedules(),
    ]);
    trackPageView({
      to: router.currentRoute.value,
    });
  },
  { immediate: true }
);

// Bus used to refetch all schedules
bus.on(async () => {
  await Promise.all([
    fetchPublishedSchedules(),
    fetchScheduledSchedules(),
    fetchPastSchedules(),
  ]);
});

// Clear bus listener and abort all requests on unmount
onUnmounted(() => {
  bus.off(async () => {
    await Promise.all([
      fetchPublishedSchedules(),
      fetchScheduledSchedules(),
      fetchPastSchedules(),
    ]);
  });
  abortPublishedSchedules();
  abortScheduledSchedules();
  abortPastSchedules();
});
</script>

<template>
  <TopbarOverview />
  <div class="flex flex-row">
    <Navigation class="fixed" />
    <main class="lg:ml-84 mr-12 mx-6 my-12 lg:w-10/12 mx-auto pt-8 pl-6 pr-16">
      <section class="flex w-full justify-between">
        <h1 class="font-semibold text-3xl">
          {{ $t('layout.mainPage.myEvents') }}
        </h1>
        <router-link :to="targetRoute">
          <Button
            v-tracking="{
              category: 'add_new_event',
              label: 'Add new event',
            }"
            variant="primary"
            round
            class="!px-10"
          >
            {{ $t('layout.mainPage.addNewEventButton') }}
          </Button>
        </router-link>
      </section>
      <article class="flex flex-col gap-y-15 mt-15">
        <EventBoxList
          :schedules="publishedSchedules"
          :title="$t('event.list.title.published')"
          :is-loading="isLoading"
        />
        <EventBoxList
          :schedules="scheduledSchedules"
          :title="$t('event.list.title.scheduled')"
          :is-loading="isLoading"
        />
        <EventBoxList
          :schedules="pastSchedules"
          :title="$t('event.list.title.past')"
          :is-loading="isLoading"
        />
      </article>
    </main>
  </div>
</template>
